import React from 'react'
import { Link } from 'gatsby'
import { AnchorLink } from 'gatsby-plugin-anchor-links'
import classNames from 'classnames'

import Navigation from 'src/components/Navigation'

import Logo from 'src/images/optimized/svg/logo.inline.svg'
import styles from './styles.module.scss'

const Header = () => {
    const headerWrapClass = classNames(styles.wrapper, 'container')
    const classNamesStartProjectBtn = classNames(styles.project, 'btn')

    return (
        <header className={styles.header}>
            <div className={headerWrapClass}>
                <AnchorLink to="/#contact" className={classNamesStartProjectBtn}>
                    Start project
                </AnchorLink>
                <Navigation />
                <Link to="/">
                    <Logo />
                </Link>
            </div>
        </header>
    )
}

export default Header
