import React from 'react'
import classNames from 'classnames'
import { Link } from 'gatsby'

import HoverableSvgSpriteIcon from 'src/components/SvgSprite/HoverableSvgSpriteIcon.js'
import Navigation from '../Navigation'

import styles from './styles.module.scss'

const Footer = () => {
    const footerWrapClass = classNames(styles.wrapper, 'container')

    return (
        <footer className={styles.footer}>
            <div className={footerWrapClass}>
                <div>
                    <div className={styles.row}>
                        <div className={styles.social}>
                            <span className={styles.socialText}>Follow us on:</span>
                            <a href="https://www.facebook.com/calatonstudio">
                                <HoverableSvgSpriteIcon
                                    className={styles.socialIcon}
                                    icon={{ usual: 'facebook', hover: 'facebookHover' }}
                                />
                            </a>
                            <a href="https://twitter.com/calatonstudio">
                                <HoverableSvgSpriteIcon
                                    className={styles.socialIcon}
                                    icon={{ usual: 'twitter', hover: 'twitterHover' }}
                                />
                            </a>
                            <a href="https://www.instagram.com/calatonstudio">
                                <HoverableSvgSpriteIcon
                                    className={styles.socialIcon}
                                    icon={{ usual: 'instagram', hover: 'instagramHover' }}
                                />
                            </a>
                            <a href="https://www.linkedin.com/company/calatonstudio/">
                                <HoverableSvgSpriteIcon
                                    className={styles.socialIcon}
                                    icon={{ usual: 'linkedln', hover: 'linkedlnHover' }}
                                />
                            </a>
                        </div>
                    </div>
                    <div className={styles.row}>
                        <div className={styles.contactsTitle}>Contacts:</div>
                        <div className={styles.email}>Email: hello@calaton.studio</div>
                        <div className={styles.phone}>Phone: +1 833-520-2828</div>
                        <div className={styles.copyright}>
                            <span>&#169; 2021 Calaton Studio. </span>
                            <span className={styles.noWrap}>
                                <Link to="/privacy">Privacy Policy.</Link>
                            </span>
                        </div>
                    </div>
                </div>
                <div className={styles.navigationFooter}>
                    <Navigation className="footerNavigationMenu" />
                </div>
            </div>
        </footer>
    )
}

export default Footer
