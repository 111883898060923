import React, { useState, useEffect } from 'react'
import PT from 'prop-types'
import { AnchorLink } from 'gatsby-plugin-anchor-links'
import classNames from 'classnames'
import { Location } from '@reach/router'

import styles from './styles.module.scss'

const Navigation = ({ className, location }) => {
    
    const menuLinks = [
        {
            text: 'Home',
            link: '/#home',
        },
        {
            text: 'About',
            link: '/#about',
        },
        {
            text: 'Services',
            link: '/#services',
        },
        {
            text: 'Cases',
            link: '/#cases',
        },
        {
            text: className === 'footerNavigationMenu' ? 'Careers' : 'Contact us',
            link: className === 'footerNavigationMenu' ? '/careers' : '/#contact',
        },
    ]

    const [open, setOpen] = useState(false)
    const [activeMenuItem, setActiveMenuItem] = useState(`/${location.pathname.split('/')[1]}`)

    const anchorLinks = menuLinks
        .filter(({ link }) => link.includes('#'))
        .map(({ link }) => link.split('/')[1])

    const getAnchorElements = () =>
        anchorLinks.map(link => document.querySelector(link)).filter(el => el !== null)

    const defineSectionViewport = anchorElements => {
        if (anchorElements.length > 0) {
            anchorElements.forEach(el => {
                const elOffsetTop = el.getBoundingClientRect().top

                if (elOffsetTop < 200 && elOffsetTop > 0) {
                    setActiveMenuItem(`/#${el.getAttribute('id')}`)
                }
            })
        }
    }

    useEffect(() => {
        let anchorElements = []

        if (location.hash) history.pushState('', document.title, window.location.pathname)

        setTimeout(() => {
            anchorElements = getAnchorElements()
            defineSectionViewport(anchorElements)
            window.addEventListener('scroll', () => defineSectionViewport(anchorElements))
        }, 100)

        return window.removeEventListener('scroll', () => defineSectionViewport(anchorElements))
    }, [])

    const openClass = classNames({ [styles.open]: open })
    const burgerOpenClass = classNames(styles.burger, openClass)
    const listOpenClass = classNames(styles.list, openClass)
    const classNamesNav = classNames(styles.nav, className)

    const getClassNamesLink = link => {
        return classNames(styles.link, { active: activeMenuItem === link })
    }

    return (
        <>
            <div
                className={burgerOpenClass}
                onClick={() => {
                    setOpen(!open)
                }}
            >
                <div className={styles.strip} />
                <div className={styles.strip} />
                <div className={styles.strip} />
            </div>
            <nav className={classNamesNav}>
                <ul className={listOpenClass}>
                    {menuLinks.map(({ text, link }) => (
                        <li
                            key={text}
                            className={styles.item}
                            onClick={() => {
                                setOpen(!open)
                            }}
                        >
                            <AnchorLink to={link} title={text} className={getClassNamesLink(link)}>
                                {text}
                            </AnchorLink>
                        </li>
                    ))}
                </ul>
            </nav>
        </>
    )
}

Navigation.propTypes = {
    location: PT.object.isRequired,
    className: PT.string,
}

const NavigationContainer = ({ className }) => (
    <Location>
        {({ location }) => <Navigation location={location} className={className} />}
    </Location>
)

NavigationContainer.propTypes = {
    className: PT.string,
}

export default NavigationContainer
