import React, { Component } from 'react'
import PT from 'prop-types'
import SvgSpriteIcon from './SvgSpriteIcon.js'

class HoverableSvgSpriteIcon extends Component {
    static propTypes = {
        children: PT.element,
        className: PT.string,
        disabled: PT.bool,
        icon: PT.object,
    }

    state = {
        hover: false,
    }

    toggleHover = () => {
        this.setState({ hover: !this.state.hover })
    }

    render() {
        const { children, className, disabled = false, icon = {} } = this.props
        return (
            <span onMouseEnter={this.toggleHover} onMouseLeave={this.toggleHover}>
                {children}
                {
                    <SvgSpriteIcon
                        name={disabled ? icon.disabled : this.state.hover ? icon.hover : icon.usual}
                        className={className}
                    />
                }
            </span>
        )
    }
}

export default HoverableSvgSpriteIcon
