import React from 'react'
import PT from 'prop-types'

import 'src/fonts/fonts.js'
import 'src/styles/global.scss'
import { SpriteLoader } from 'src/components/SvgSprite'

import style from './style.module.scss'

const CommonImports = ({ children }) => {
    return (
        <div className={style.page}>
            <SpriteLoader />
            {children}
        </div>
    )
}

CommonImports.propTypes = {
    children: PT.any,
}

export default CommonImports
